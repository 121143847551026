<template>
    <div class="market_detail">
        <Header backType="white" :back="true">
        </Header>
        <div class="merchantContent">
            <div class="merchantGroup">
                <div style="margin-bottom: 10px" class="merchantGroupHead">订单状态：{{takeOutDetail.statusTxt}}</div>
                <div class="merchantGroupHead">商品信息</div>
                <div class="merchantMoList marketList">
                    <div class="marketLi" v-for="(item,index) in sectionList" :key="index">
                        <span class="merchantName overflow">{{item.name}}</span>
                        <span class="merchantMoPrice">{{item.price | price}}</span>
                        <span class="marketNum">{{item.num | count}}</span>
                    </div>
                    <div class="merchantMoAllPrice marketLi">
                        快递费
                        <span class="merchantMoAllPriceNum">{{takeOutDetail.shippingFee | price}}</span>
                    </div>
                    <div class="merchantMoAllPrice marketLi">
                        总金额
                        <span class="merchantMoAllPriceNum">{{takeOutDetail.amount | price}}</span>
                    </div>
                </div>
            </div>
            <div class="merchantGroup">
                <div class="merchantGroupHead">配送信息</div>
                <div class="merchantMoList marketList">
                    <div class="merchantMoAllPrice marketLi">
                        <span>送达时间</span>
                        <span style="width: 70%;text-align: right;margin-left: .1rem;" class=" overflow">{{takeOutDetail.arriveTime}}</span>
                    </div>
                    <div class="merchantMoAllPrice marketLi">
                        <span>送货地址</span>
                        <span style="width: 70%;text-align: right;margin-left: .1rem;" class=" overflow">{{takeOutDetail.address}}</span>
                    </div>
                    <div class="merchantMoAllPrice marketLi">
                        <span style="width: 3em">收货人</span>
                        <span  style="width: 70%;text-align: right;margin-left: .1rem;" class=" overflow">{{takeOutDetail.name}}&emsp; {{takeOutDetail.mobile}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import mealDdianPing from "@/components/Meal/mealDdianPing.js";
export default {
    name: "superMarketDetail",
    mixins: [mealDdianPing],
    data() {
        return {
            takeOutDetail: {},
            sectionList: [],
            style: {
                width: "0.3rem",
                height: "0.3rem",
                borderRadius: "50%",
                background: "rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }
        };
    },
    computed: {
        ...mapGetters(["orderDetail"])
    },
    mounted() {
        this.getDetail();
    },
    filters: {
        price: value => {
            if (value || value === 0) {
                return "¥" + value;
            }
        },
        count: value => {
            if (value) {
                return "X" + value;
            }
        }
    },
    methods: {
        getDetail() {
            let params = {
                itemId: this.orderDetail.itemId
            };
            miceService.getJDOrderDetail(params).then(res => {
                if (res && res.success) {
                    this.takeOutDetail = res.content;
                    // this.total = res.content.total || {};
                    this.sectionList = res.content.skuList || {};
                }
            });
        }
    }
};
</script>

<style lang="scss">
.market_detail{
    padding-bottom: .3rem;
    .merchantContent {
        padding: 0 2.5%;
    }
    .detail_title{
        text-align: left;
        font-size: .2rem;
        font-weight: 500;
        color: #13161B;
    }
    .merchantName {
        font-size: 0.15rem;
        text-align: left;
        color: #13161A;
    }
    
    .merchantMsg {
        font-size: .14rem;
        color: #909399;
        padding: .1rem 0;
        border-bottom: 1px solid #F5F5F5;
        p {
            text-align: left;
        }
    }
    
    
    .merchantBtnRow {
        margin-top: 0.2rem;
        text-align: left;
    
        .imgContainer {
            div {
                float: left;
                margin: 0.1rem 0.1rem 0 0;
            }
        }
    
        img {
            width: 0.56rem;
            height: 0.56rem;
            border-radius: .05rem;
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
        }
    
        .nodata {
            text-align: left;
            margin-top: 0.1rem;
            color: rgb(153, 153, 153);
        }
    }
    
    .merchantBtnRow:after {
        display: block;
        clear: both;
        content: "";
        visibility: hidden;
        height: 0
    }
    
    .merchantBtn {
        width: 1rem;
        padding-left: 0.15rem;
        height: 0.44rem;
        line-height: 0.44rem;
        position: relative;
        text-align: center;
        font-size: .14rem;
        border-radius: 0.22rem;
        margin-right: .15rem;
        float: left;
        background: var(--themeColor);
    }
    
    .merchantBtnSvg {
        position: absolute;
        width: 0.15rem !important;
        height: 0.15rem !important;
        left: 0.25rem;
        top: .13rem;
    }
    
    .merchantGroup {
        margin-top: 0.1rem;
        border-bottom: 1px solid #F5F5F5;
    }
    
    .merchantGroupHead {
        font-size: 0.16rem;
        color: #13161A;
        text-align: left;
        font-weight: bold;
    }
    
    .merchantGroupList,
    .merchantMoList {
        color: #13161A;
        font-size: .14rem;
    }
    
    .merchantGroupList div {
        text-align: left;
        margin-bottom: .05rem;
        display: flex;
        height: .2rem;
    }
    
    .merchantGroupLabel {
        display: inline-block;
        width: 1rem;
        color: #13161b;
        text-align: left;
        font-size: .15px;
    }
    
    .merchantGroupTxt {
        width: calc(100% - 1rem);
        text-align: right!important;
        font-size: .15px;
    }
    
    .merchantFont {
        color:  var(--themeColor);
        font-weight: 500;
    }
    
    .merchantSuccess {
        color: #4AC672;
    }
    
    .merchantOk {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    
    .merchantMoList div {
        margin-bottom: .05rem;
        color: #909399;
        text-align: left;
        font-size: .14rem;
        display: flex;
        justify-content: space-between;
    }
    
    .merchantMoNum,
    .merchantMoPrice {
        color: #13161A;
        font-weight: 500;
    }
    
    .merchantMoList li:after {
        display: block;
        clear: both;
        content: "";
        visibility: hidden;
        height: 0
    }
    .AllPrice{
        .AllPrice_price{
            font-weight: bold;
            color: #13161A;
            span{
                font-weight: 500;
                color: #909398;
            }
        }
    }
    .merchantMoAllPrice {
        width: 100%;
        text-align: right !important;
        margin-top: 0.1rem;
        color: #13161A !important;
        display: flex;
        justify-content: space-between;
        
    }
    
    .merchantMoAllPriceNum {
        font-weight: 500;
    }
    .gogps{
        width: calc( 100% - 1rem );
        display: flex;
        justify-content: flex-end;
    }
    .call_phone{
        width: calc( 100% - 1rem);
        display: flex;
        justify-content: flex-end;
    }
    .color_3875FE{
        color: var(--themeColor);
    }
    .color_4AC672{
        color: #4AC672;
    }
    .detail-mark{
        .merchantGroupTxt{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
        }
    }
}








.merchantImg {
    width: 100%;
    z-index: 1;
    height: 1rem;
}
.merchantName{
    color: #909399;
    font-size: .14rem;
}
.overflow{
    white-space: nowrap;
    text-overflow:ellipsis; 
    overflow:hidden;
}
.marketLi{
    display: flex;
    justify-content: space-between;
    margin-top: .1rem;
}
</style>